import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import defineAbilitiesFor, { RolesInterface } from "../../../config/ability";
import { AbilityContext } from "./AbilityContext";

const PrivateRoutes = () => {
  // Assuming you have a mechanism to retrieve the current user's roles, e.g., from local storage
  const currentUserRoles = useMemo(() => {
    // Retrieve user roles from local storage or another persistence mechanism
    const user = JSON.parse(localStorage.getItem("user") || "{}");

    return user.roles || ["guest"]; // Default to "guest" role if no roles are found
  }, []);

  const ability = useMemo(
    () => defineAbilitiesFor(currentUserRoles),
    [currentUserRoles],
  );

  return (
    <AbilityContext.Provider value={ability}>
      <Outlet />
    </AbilityContext.Provider>
  );
  // : <Navigate to="/not-authorized" />;
};

export default PrivateRoutes;
