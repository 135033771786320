import React from "react";
import { Navigate } from "react-router-dom";
import { useCan } from "../../../../hooks/useCan";

export interface ProtectedRouteInterface {
  Component: React.FC;
  on: string;
  perform?: string;
  props?: any;
}
const ProtectedRoute = ({
  Component,
  on,
  perform = "read",
  props = {},
}: ProtectedRouteInterface) => {
  const canAcccessPage = useCan(perform, on);

  return canAcccessPage ? (
    <Component {...props} />
  ) : (
    <Navigate to="/not-authorized" />
  );
};

export default ProtectedRoute;
