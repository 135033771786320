export type CustomLabelProps = {
  title: string;
  htmlFor: string;
  children?: any;
};
const CustomLabel: React.FC<CustomLabelProps> = ({
  title,
  htmlFor,
  children,
}) => {
  return (
    <label className="text-black dark:text-white text-sm sm:text-base md:text-xl " htmlFor={htmlFor}>
      {title}
      {children}
    </label>
  );
};

export default CustomLabel;
