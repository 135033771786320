import "./App.css";

import React from "react";
import { useRoutes } from "react-router-dom";

import routes from "./router";
import { ToastContainer } from "react-toastify";

function App() {
  // Using the useRoutes hook to handle routing based on the defined routes
  const content = useRoutes(routes());

  return (
    <div className="App">
      <>
        <ToastContainer toastStyle={{ width: "fit-content" }} />
      </>
      {content}
    </div>
  );
}

export default App;
