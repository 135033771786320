import React, { lazy, Suspense } from "react";
import SuspenseLoader from "./components/Loader/Suspense";
import Layout from "./layout";
import PrivateRoutes from "./middleware/Authentification/PrivateRoutes";
import ProtectedRoute from "./middleware/Authentification/PrivateRoutes/ProtectedRoute";
import { Navigate } from "react-router-dom";

const Loader =
  (Component: React.FC, on: string, loginRequired: boolean = true) =>
  (props: any) => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        {loginRequired ? (
          <ProtectedRoute
            Component={Component}
            on={on}
            perform="manage"
            props={props}
          />
        ) : (
          <Component />
        )}
      </Suspense>
    );
  };

const HomePage = Loader(
  lazy(() => import("./pages/Home")),
  "HomePage",
  false,
);

const CourseDetailsPage = Loader(
  lazy(() => import("./pages/CourseDetails")),
  "CourseDetailsPage",
  false,
);
const CampusesPage = Loader(
  lazy(() => import("./pages/SeperatedAdmins/Campuses")),
  "CampusesPage",
);
const DepartmentsPage = Loader(
  lazy(() => import("./pages/SeperatedAdmins/Departments")),
  "DepartmentsPage",
);
const CoursesPageAdmin = Loader(
  lazy(() => import("./pages/SeperatedAdmins/Courses")),
  "CoursesPageAdmin",
);

const QuestionBanks = Loader(
  lazy(() => import("./pages/QuestionBanks")),
  "QuestionBanks",
);
const Status404 = Loader(
  lazy(() => import("./pages/Helpful/Error/404")),
  "Status404",
  false,
);
const Status401 = Loader(
  lazy(() => import("./pages/Helpful/Error/401")),
  "Status401",
  false,
);
const LoginPage = Loader(
  lazy(() => import("./pages/Login")),
  "LoginPage",
  false,
);

const QuizGame = Loader(
  lazy(() => import("./pages/QuizGame")),
  "QuizGame",
);
const JoinQuizGame = Loader(
  lazy(() => import("./pages/JoinQuizGame")),
  "JoinQuizGame",
  false,
);
const SummariesPage = Loader(
  lazy(() => import("./pages/Summaries")),
  "SummariesPage",
);
const UsersPage = Loader(
  lazy(() => import("./pages/UserManagement/Users")),
  "UsersPage",
);
const QuizGameParticipate = Loader(
  lazy(() => import("./pages/QuizGameParticipate")),
  "QuizGameParticipate",
  false,
);
const PromptsPage = Loader(
  lazy(() => import("./pages/Prompts")),
  "PromptsPage",
  false,
);
const CreateExam = Loader(
  lazy(() => import("./pages/CreateExam")),
  "CreateExam",
);
const CalendarPage = Loader(
  lazy(() => import("./pages/Calendar")),
  "CalendarPage",
  false,
);
const ExamQuestions = Loader(
  lazy(() => import("./pages/ExamQuestions")),
  "ExamQuestions",
);
const ExamTake = Loader(
  lazy(() => import("./pages/ExamTake")),
  "ExamTake",
);
const ProfilePage = Loader(
  lazy(() => import("./pages/ProfilePages/Profile")),
  "ProfilePage",
);
const ProfileGuestPage = Loader(
  lazy(() => import("./pages/ProfilePages/ProfileGuestView")),
  "ProfileGuestPage",
);
const TopicsPage = Loader(
  lazy(() => import("./pages/Topics")),
  "TopicsPage",
  true,
);

const PracticeExamPage = Loader(
  lazy(() => import("./pages/PracticeExam")),
  "PracticeExamPage",
  false,
);

const PracticeHistoryPage = Loader(
  lazy(() => import("./pages/PracticeExam/PracticeExamStats")),
  "PracticeHistoryPage",
  false,
);

const PracticeExamQuestionsRootPage = Loader(
  lazy(() => import("./pages/PracticeExam/PracticeExamQuestionsRoot")),
  "PracticeExamQuestionsRootPage",
  false,
);

const PracticeExamStatsPage = Loader(
  lazy(() => import("./pages/PracticeExam/PracticeExamStats")),
  "PracticeExamStatsPage",
  false,
);

const PracticeExamQuestionPage = Loader(
  lazy(
    () =>
      import(
        "./pages/PracticeExam/PracticeExamQuestionsRoot/PracticeExamQuestion"
      ),
  ),
  "PracticeExamQuestionPage",
  false,
);

const StatisticsPage = Loader(
  lazy(() => import("./pages/Statistics")),
  "StatisticsPage",
  false,
);

const routes = () => [
  {
    element: <PrivateRoutes />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            path: "/",
            element: <HomePage />,
          },
          {
            path: "/courses/:courseID/exam/:examID/take/:studentExamID",
            element: <ExamTake />,
          },
          {
            path: "/courses/:courseID/createExam",
            element: <CreateExam />,
          },
          {
            path: "/users",
            element: <UsersPage />,
          },
          {
            path: "/courses/:courseID",
            element: <CourseDetailsPage />,
          },

          {
            path: "/campuses",
            element: <CampusesPage />,
          },
          // {
          //   path: "/calendar",
          //   element: <CalendarPage />,
          // },
          {
            path: "/campuses/:campusID/departments",
            element: <DepartmentsPage />,
          },
          {
            path: "/campuses/:campusID/departments/:departmentID/courses",
            element: <CoursesPageAdmin />,
          },

          {
            path: "/campuses/:campusID/departments/:departmentID/courses/:courseID",
            element: <CourseDetailsPage />,
          },
          {
            path: "/courses/:courseID/questionsBank/:questionBankID",
            element: <QuestionBanks />,
          },
          {
            path: "/myClasses/:courseID/questionsBank/:questionBankID",
            element: <QuestionBanks />,
          },
          {
            path: "/courses/:courseID/exam/:examID",
            element: <ExamQuestions />,
          },
          {
            path: "/courses/:courseID/material/:materialID",
            element: <SummariesPage />,
          },

          {
            path: "/quizGame/:quizGameID",
            element: <QuizGame />,
          },

          {
            path: "/profile",
            element: <ProfilePage />,
          },
          { path: "/profile/:userID", element: <ProfileGuestPage /> },
          {
            path: "/topics",
            element: <TopicsPage />,
          },
          {
            path: "/practice/history/:examID",
            element: <PracticeHistoryPage />,
          },
          {
            path: "/practice/:examID",
            element: <PracticeExamPage />,
            children: [
              {
                path: "",
                element: <PracticeExamQuestionsRootPage />,
                children: [
                  {
                    path: "question/:questionNumber",
                    element: <PracticeExamQuestionPage />,
                  },
                  {
                    path: "",
                    element: <Navigate replace to={"question/1"} />,
                  },
                ],
              },
              {
                path: "stats",
                element: <PracticeExamStatsPage />,
              },
            ],
          },
          {
            path: "/stats",
            element: <StatisticsPage />,
          },
          {
            path: "*",
            element: <Status404 />,
          },
          {
            path: "/not-authorized",
            element: <Status401 />,
          },
          {
            path: "/prompts",
            element: <PromptsPage />,
          },
        ],
      },
    ],
  },

  {
    path: "/login",
    element: <LoginPage />,
  },

  { path: "/joinQuizGame", element: <JoinQuizGame /> },
  {
    path: "/quizGame/:quizGameID/:userID",
    element: <QuizGameParticipate />,
  },
];

export default routes;
