import "./index.css";

import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { CreateObjectModalInterface } from "./index.d";

import useOutsideClick from "../../../hooks/useOutsideClick";
import { IoCloseCircle } from "react-icons/io5";

const CreateObjectModal = ({
  handleClose,
  handleSubmit,
  children,
  modalName,
  submit_text = "Create",
  actionPart,
}: CreateObjectModalInterface) => {
  const modalRef = useOutsideClick(handleClose);
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center px-2 py-2 backdrop-blur-sm">
      <div
        id="inner-elements"
        className="w-full rounded-2xl bg-white  shadow-2xl  dark:bg-[#282c34] sm:w-3/4 md:w-1/2 lg:w-[800px] "
        ref={modalRef}
      >
        {/* Modal Header */}
        <div
          id="modal-header"
          className="flex items-center justify-between p-4  font-bold text-black dark:text-white"
        >
          <h2 className="text-2xl">{modalName}</h2>
          <button
            id="close-modal"
            type="button"
            className="cursor-pointer"
            onClick={handleClose}
          >
            <IoCloseCircle size={30} className={"hover:opacity-70"} />
          </button>
        </div>
        {/* Divider */}
        <hr className="  w-full border-t-2 border-main-gray-500" />
        <form
          className=" mx-auto my-auto max-h-[72vh]  w-full space-y-6  px-4 pb-2 pt-2 font-bold text-black dark:text-white "
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-4">
            <div className="scrollbar-hide mx-auto my-auto flex max-h-[60vh] w-full  flex-col space-y-6 overflow-y-scroll px-4 py-2 font-bold text-black dark:text-white ">
              {children}
            </div>
            <div id="modal-actions" className=" flex justify-end gap-2">
              {actionPart}
              {handleSubmit && (
                <button
                  className="flex items-center justify-center gap-2 rounded-full bg-main-green-700 px-4 py-2
                   text-lg font-bold text-white hover:bg-main-green-500 dark:bg-main-green-500
                      dark:text-black dark:hover:bg-main-green-700"
                  type="submit"
                >
                  {submit_text}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateObjectModal;
