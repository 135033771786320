import { useState, useEffect } from "react";
import { useLocalStorageState } from "./useLocalStorageState";
export function includesAny(arr: any[], valuesToCheck: any[]) {
  return valuesToCheck.some((value) => arr.includes(value));
}

export function useAccessLevelStatus<T>() {
  const [user, setUser] = useLocalStorageState<any>("user", null);

  // Save the state to local storage whenever it changes
  useEffect(() => {}, [user]);
  if (!user || !user.roles)
    return { admin: false, teacher: false, student: false };
  // Return the state and a function to update it
  return {
    admin: includesAny(user.roles, ["admin"]),
    teacher: includesAny(user.roles, ["teacher"]),
    student: includesAny(user.roles, ["student"]),
  };
}
