import DefaultService from "../Default";
const COLLECTION = "breadcrumbs";

class BreadcrumbsService extends DefaultService {
  constructor() {
    super(COLLECTION);
  }
  async getBreadcrumsData(pathname: string): Promise<any> {
    const query = `?url=${encodeURIComponent(pathname)}`;
    const res = await this.fetchJson<any>(query, {
      method: "GET",
    });
    return res;
  }
}

export default BreadcrumbsService;
