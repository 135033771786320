import { useState, useEffect } from "react";

interface UserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  university?: any;
  userID?: string;
}

const useUserInfo = (): UserInfo => {
  const [userInfo, setUserInfo] = useState<UserInfo>({});

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        const { email, firstName, lastName, _id } = JSON.parse(storedUser).user;

        let username = "";

        if (firstName) {
          username += firstName.charAt(0).toUpperCase() + firstName.slice(1);
        }

        if (lastName) {
          username +=
            " " + lastName.charAt(0).toUpperCase() + lastName.slice(1);
        }

        setUserInfo((prev) => ({
          ...prev,
          userID: _id,
          email,
          firstName,
          lastName,
          username,
        }));
      } catch (err) {}
      try {
        setUserInfo((prev) => ({
          ...prev,
          university: JSON.parse(storedUser).university,
        }));
      } catch (err) {}
    }
  }, []);

  return userInfo;
};

export default useUserInfo;
