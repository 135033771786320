import Cookies from "js-cookie";

export const ProfileService = {
  getToken: () => {
    // Retrieve the token from cookies instead of local storage
    return Cookies.get("token");
  },
  isAuthenticated: () => {
    // Check if the cookie containing the token exists
    return Cookies.get("token") != null;
  },
};
