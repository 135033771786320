import { useState, useEffect } from "react";

export function useLocalStorageState<T>(
  key: string,
  defaultValue: T,
): [T, (value: T) => void] {
  // Initialize the state with the value from local storage (if available) or with the default value
  const [state, setState] = useState<T>(() => {
    const serializedState = localStorage.getItem(key);
    if (serializedState !== undefined && serializedState !== null) {
      try {
        return JSON.parse(serializedState);
      } catch (err) {
        return defaultValue;
      }
    }
    return defaultValue;
  });

  // Save the state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  // Return the state and a function to update it
  return [state, setState];
}
