import React, { useState, useCallback } from "react";

export type OpenState = {
  [key: string]: boolean;
};

export const useOpenState = (
  initialState: OpenState,
): [
  OpenState,
  (name: string, value: boolean) => void,
  (name: string) => void,
  () => boolean,
  () => boolean,
] => {
  const [isOpen, setIsOpen] = useState<OpenState>(initialState);

  const handleIsOpen = useCallback((name: string, value: boolean) => {
    setIsOpen((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleOpenClose = useCallback((name: string) => {
    setIsOpen((prev) => ({ ...prev, [name]: !prev[name] }));
  }, []);

  const isAnyTrue = useCallback(() => {
    return Object.values(isOpen).some((value) => value === true);
  }, [isOpen]);

  const isAnyFalse = useCallback(() => {
    return Object.values(isOpen).every((value) => value === false);
  }, [isOpen]);

  return [isOpen, handleIsOpen, handleOpenClose, isAnyTrue, isAnyFalse];
};
