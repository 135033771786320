import { FC } from "react";
import CustomLabel from "../../labels";
import { InputFieldBasicProps } from "./index.d";

const InputFieldBasic: FC<InputFieldBasicProps> = ({
  label,
  id,
  type = "text",
  ...props
}) => (
  <div className="flex w-full flex-col items-start justify-center gap-2">
    {label && <CustomLabel title={label} htmlFor={id} />}
    <input
      id={id}
      type={type}
      className="w-full rounded border bg-transparent p-2"
      {...props}
    />
  </div>
);

export default InputFieldBasic;
