import "./index.css";

import NProgress from "nprogress";
import { useEffect } from "react";

import { SuspenseLoaderInterface } from "./index.d";

function SuspenseLoader({ title = "...Loading" }: SuspenseLoaderInterface) {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return <p className="text-black dark:text-white">{title}</p>;
}

export default SuspenseLoader;
