// Redux => Reducers=> theme.ts
import { createSlice } from '@reduxjs/toolkit';
import { ThemeState, ThemeVariant } from '../Types/theme';

const THEME_KEY = 'theme';
const deviceTheme = window.matchMedia?.('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
const initialState: ThemeState = {
  currentTheme: (localStorage.getItem(THEME_KEY) as ThemeVariant)|| deviceTheme || 'dark'
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
        const root: HTMLElement = window.document.documentElement;
        root.classList.remove(state.currentTheme);
        state.currentTheme = state.currentTheme === 'dark' ? 'light' : 'dark';
        root.classList.add( state.currentTheme);  
        localStorage.setItem(THEME_KEY, state.currentTheme);
    }
  }
});

export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;
